import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../helpers";

type Props = {
  className?: string;
  left?: boolean;
  maxWidth: number;
};

const ImageWrapper: FunctionComponent<Props> = ({
  left,
  maxWidth,
  children,
  className,
}) => {
  return (
    <div
      className={mergeClassNames(left ? "" : "my-0 mx-auto", className)}
      style={{ maxWidth: `${maxWidth}px` }}
    >
      {children}
    </div>
  );
};

export default ImageWrapper;
