import {
  throttle,
  mergeClassNames,
  prefersReducedMotion,
  isSafari,
  generateRandomString,
  isIE,
} from "../../helpers";
import Img from "gatsby-image/withIEPolyfill";
import React, { FunctionComponent, useState, useEffect } from "react";

// @ts-ignore
import { SCREENS } from "../../constants.js";

import "./HeroImage.scss";
import { graphql } from "gatsby";
import Paragraph from "../Paragraph";
import mobileBoxSrc from "../../images/hero-box-mobile.svg";
import mobileBoxSrcWithoutAnimation from "../../images/hero-box-mobile-without-animation.svg";
import objectFitImages from "object-fit-images";

type ImagePosition = { x: number; y: number };

type Props = {
  fluidQuery: any;
  mobileFluidQuery?: any;
  headLine: JSX.Element | string;
  content?: JSX.Element | string;
  footer?: JSX.Element;
  boxSrc: string;
  boxSrcWithoutAnimation: string;
  imagePosition: {
    desktop: ImagePosition;
    mobile: ImagePosition;
  };
  isHomeHero?: boolean;
  className?: string;
  homeMobileBoxSrc?: string;
  homeMobileBoxSrcWithoutAnimation?: string;
};

const HeroImage: FunctionComponent<Props> = ({
  fluidQuery,
  mobileFluidQuery,
  headLine,
  content,
  footer,
  boxSrc,
  boxSrcWithoutAnimation,
  imagePosition,
  isHomeHero,
  className,
  homeMobileBoxSrc,
  homeMobileBoxSrcWithoutAnimation,
}) => {
  const [computedImagePosition, setComputedImagePosition] = useState({
    x: 50,
    y: 50,
  });
  const [showBox, setShowBox] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setComputedImagePosition(imagePosition.desktop);
      } else {
        setComputedImagePosition(imagePosition.mobile);
      }
    };
    handleResize();
    const throttledResizeHandler = throttle(handleResize, 200);
    window.addEventListener("resize", throttledResizeHandler);

    objectFitImages();

    return () => window.removeEventListener("resize", throttledResizeHandler);
  }, []);

  const animationEnabled = () => {
    return isSafari() || isIE() || prefersReducedMotion();
  };
  const getDesktopBoxSrc = () => {
    if (animationEnabled()) {
      return boxSrcWithoutAnimation;
    }
    return preventCaching(boxSrc);
  };
  const getMobileBoxSrc = () => {
    if (animationEnabled()) {
      if (mobileBoxSrcWithoutAnimation || homeMobileBoxSrcWithoutAnimation) {
        let src = mobileBoxSrcWithoutAnimation;
        if (isHomeHero && homeMobileBoxSrcWithoutAnimation) {
          src = homeMobileBoxSrcWithoutAnimation;
        }
        return src;
      }
    }
    let src = mobileBoxSrc;
    if (isHomeHero && homeMobileBoxSrc) {
      src = homeMobileBoxSrc;
    }
    return preventCaching(src);
  };
  const preventCaching = (src: string) => {
    return src + "?preventCaching=" + generateRandomString();
  };

  const handleHeroImageOnLoad = () => {
    setShowBox(true);
  };

  return (
    <div
      className={mergeClassNames(
        `HeroImage ${isHomeHero ? " HeroImage--home" : ""}`,
        className
      )}
    >
      <Img
        objectPosition={`${computedImagePosition.x}% ${computedImagePosition.y}%`}
        className="HeroImage__image"
        fluid={fluidQuery}
        onLoad={handleHeroImageOnLoad}
      />
      {isHomeHero && (
        <Img
          objectPosition={`${computedImagePosition.x}% ${computedImagePosition.y}%`}
          className="HeroImage__image HeroImage__image--mobile"
          fluid={mobileFluidQuery}
          onLoad={handleHeroImageOnLoad}
        />
      )}

      {showBox ? (
        <>
          <img
            className="HeroImage__box-svg"
            src={getDesktopBoxSrc()}
            style={{
              objectPosition: `${imagePosition.desktop.x}% ${imagePosition.desktop.y}%`,
              fontFamily: `'object-fit: cover; object-position: ${imagePosition.desktop.x}% ${imagePosition.desktop.y}%'`,
            }}
            alt=""
          />
          <img
            className="HeroImage__box-svg HeroImage__box-svg--mobile"
            src={getMobileBoxSrc()}
            style={{
              objectPosition: `${computedImagePosition.x}% ${computedImagePosition.y}%`,
              fontFamily: `'object-fit: cover; object-position: ${imagePosition.mobile.x}% ${imagePosition.mobile.y}%'`,
            }}
            alt=""
          />
        </>
      ) : null}
      <div
        className={
          isHomeHero
            ? "container relative z-10 mb-8 md:mb-0"
            : "container relative z-10 mt-6"
        }
      >
        <div className="HeroImage__headline px-2 md:w-1/2 lg:w-5/12">
          <h1
            style={{
              boxDecorationBreak: "clone",
              WebkitBoxDecorationBreak: "clone",
            }}
            className={
              isHomeHero
                ? "font-bold antialiased text-black-dark padded-multi-line-white "
                : "text-white font-bold antialiased padded-multi-line-black md:text-black-dark md:padded-multi-line-white"
            }
          >
            <span className="relative">{headLine}</span>
          </h1>
        </div>
        <div className="md:w-2/5 mt-2 mb-8">
          {content ? (
            <Paragraph className="md:text-white">{content}</Paragraph>
          ) : null}
        </div>
        {footer ? <div className="mt-4 md:w-1/2">{footer}</div> : null}
      </div>
    </div>
  );
};

export default HeroImage;

export const heroQuery = graphql`
  fragment HeroQuery on File {
    childImageSharp {
      fluid(maxWidth: 4000, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
